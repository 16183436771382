import './style.scss';

const Contact = () => {

  return(
    <div className="home-wrapper">
      <h1>Contact Page</h1>
    </div>
  )
};

export default Contact;