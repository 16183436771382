import './style.scss';

const About = () => {

  return(
    <div className="home-wrapper">
      <h1>About Page</h1>
    </div>
  )
};

export default About;