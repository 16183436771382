import {
  SAVE_HOME_PAGE_VALUE_SUCCESS,
  SAVE_HOME_PAGE_VALUE_FAILED,
  SUBMIT_INFO_REQUEST_SUCCESS,
  SUBMIT_INFO_REQUEST_FAILED
} from "./actionTypes";

const initialState = {
  testArray: [],
  success: false,
  failed: false
}

const HomePageReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_HOME_PAGE_VALUE_SUCCESS:
      return {
        ...state,
        success: action.payload.success,
        testArray: action.payload.response
      }
    case SAVE_HOME_PAGE_VALUE_FAILED:
      return {
        ...state,
        failed: action.failed
      }
    case SUBMIT_INFO_REQUEST_SUCCESS:
      return {
        ...state,
        success: action.payload.success
      }
    case SUBMIT_INFO_REQUEST_FAILED:
      return {
        ...state,
        failed: action.failed
      }
    default:
      return state;
  }
}

export default HomePageReducer;