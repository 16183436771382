import Header from './components/header';
import Footer from './components/Footer';
import {Routes,Route} from 'react-router-dom';
import Home from './containers/Home';
import About from './containers/About';
import Services from './containers/Services';
import Products from './containers/Products';
import Contact from './containers/Contact';
import "./App.scss";

function App() {
  return (
    <div className="App">
      <Header/>
        <Routes>
          <Route path='/' index element={<Home/>} />
          <Route path='/about' index element={<About/>} />
          <Route path='/services' index element={<Services/>} />
          <Route path='/products' index element={<Products/>} />
          <Route path='/contact' index element={<Contact/>} />
        </Routes>
      <Footer/>
    </div>
  );
}

export default App;
