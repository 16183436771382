import React from 'react';
import './style.scss';

const ArrowButton = (props) => {
  return (
    <>
      <div className="products-btn">
        <a href={props.href} className="btn">
          <div className="btn-bg"></div>
          <div className="arrow">
            <div className="arrow-line arrow-line-1"></div>
            <div className="arrow-line arrow-line-2"></div>
            <div className="arrow-line arrow-line-3"></div>
          </div>
          <span className="btn-text">{props.buttonText}</span>
        </a>
      </div>
    </>
  )
}

export default ArrowButton;