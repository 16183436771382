import React, { useEffect } from 'react';
import './style.scss';
import { useDispatch } from 'react-redux';
import { initHomePageValue } from '../../store/home/actions';
import ReactPlayer from 'react-player'
import logoImage from './images/getBreadMitten.png'
import IconCard from '../../components/IconCard';
import Testimonials from '../../components/Testimonials';
import EmailForm from '../../components/EmailForm';
import ContactForm from '../../components/ContactForm';
import ComputerScreen from '../../images/svg/computerscreen';
import SocialMedia from '../../images/svg/socialmedia';
import VideoEditing from '../../images/svg/socialmedia';
import ArrowButtonInverse from '../../components/ArrowButtonInverse';
import ProcessCard from '../../components/ProcessCard';
import qualityImage from './images/quality.png';
import computerImage from './images/9.png';
import webchain from './images/webchain.png';
import stockmarket from './images/stockmarket.png';
import LightBulb from '../../images/svg/lightbulb';
import Marketing from '../../images/svg/marketing';
import Growth from '../../images/svg/growth';

const Home = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initHomePageValue())
  }, [dispatch])

  return (
    <>
      <section className="home">
        <div className="home-hero">
          <div className="home-hero-container">
            <div className="home-hero-container-emailinput">
              <span className="home-hero-container-emailinput-heading">
                Welcome to the Bakery
              </span>
              <br></br>
              <span className="home-hero-container-emailinput-sub-heading">
                This is where the good stuff gets made.
              </span>
              <div className="divider"></div>
              <EmailForm
                width="30rem"
              />

            </div>
            <div className="home-hero-container-image">
              <img src={logoImage} alt="logo"></img>
            </div>
          </div>
        </div>
      </section>

      <section className="icons">
        <div className="icons-flex-item">
          <IconCard
            iconImage={ComputerScreen}
            headingText="Beautiful Sleek Websites"
            paragraphText="Your website is your stage on the internet,
            so lets put on a show!"
            href="#"
            buttonText="About Us"
          />
        </div>
        <div className="icons-flex-item">
          <IconCard
            iconImage={SocialMedia}
            headingText="Social Media Management"
            paragraphText="Let us handle the algorithms so you can focus 
            on doing what you do best"
            href="#"
            buttonText="Testimonials"
          />
        </div>
        <div className="icons-flex-item">
          <IconCard
            iconImage={VideoEditing}
            headingText="Video & Podcast Editing"
            paragraphText="Let us take on the workload of editing so you
            can get back to making content"
            href="#"
            buttonText="Portfolio"
          />
        </div>
      </section>

      <section className="whoWeAre">
        <div className="whoWeAre-description">
          <h1 className="whoWeAre-description-heading">Who We Are</h1>
          <p className="whoWeAre-description-text">
            Get Bread at it's core is a family company. The three Stahlmann's have a collective 35+ years of experience between them.
            With Papa Matthew's expertise in back-end development, Seth's hard-earned skills in design and motion graphics,and Sky's hands on
            experience as a social media and creative strategist, this homegrown team has been destined to join forces and kick ass.
          </p>
          <ArrowButtonInverse
            href="#"
            buttonText="Learn More"
          />
        </div>
        <ReactPlayer url="https://www.youtube.com/watch?v=HD13eq_Pmp8" />
      </section>

      <section className="process">
        <h1 className="process-heading">Our Process</h1>
        <div className="process-divider"></div>
        <div className="process-circle1"></div>
        <div className="process-card1">
          <ProcessCard
            icon={LightBulb}
            text1="Step 1"
            text2="Branding"
            paragraph="Our first priority is to figure out what the essential ingredients are for your brand. 
            We work with you to develop a detailed visual style guide, mission statement, logo, 
            brand colors,creatives for all social profiles,marketing narratives, and everything 
            else that makes up the building blocks of your online image."
          />
        </div>

        <img className="process-img1" alt="process-img1" src={qualityImage} />

        <img className="process-img2" alt="process-img2"src={computerImage} />

        <div className="process-circle2"></div>
        <div className="process-card2">
          <ProcessCard
            icon={LightBulb}
            text1="Step 2"
            text2="Website"
            paragraph="This is your grand stage on the internet, lets make it AWESOME! Whether you require hyper-custom
            functionality, ecommerce, unique architechture, or just a simple blog, we've got you covered.
            No job is too complicated, or too simple for us. We'll pair our knowledge of code, and out cutting edge sense of style to 
            make you the perfect website that will fit your brand like a tailored suit."
          />
        </div>

        <img className="process-img3" alt="process-img3" src={webchain} />

        <div className="process-circle3"></div>
        <div className="process-card3">
          <ProcessCard
            icon={Marketing}
            text1="Step 3"
            text2="Marketing"
            paragraph="In today's world, virtual communities are a MUST for any brand looking to develop income from virtual users.
            To overlook these key aspects of your business is to sleep on untold revenue. Let's chat about how we can build a 
            thriving online community for you and keep the customers coming in and coming back."
          />
        </div>

        <div className="process-card4">
          <ProcessCard
            icon={Growth}
            text1="Step 4"
            text2="Growth"
            paragraph="Congratulations! You are an established (or newly renovated) brand. Feels nice huh? 
            You’re ready to begin your journey to your ideal online community. Every month, we will meet to discuss upcoming campaigns, 
            solidify our content strategy, and to review analytics and content performance for growth opportunities. 
            Rome wasn’t built in a day, and we’re here for the long haul. While every brand is going to have a different journey to their goals, 
            we aim to get your platform on a path to consistent growth and a 20% average engagement rate within six months. 
            Consider the Get Bread family as your personal guide to your ideal online community. 
            
            Pssst… feel free to check out some a la carte services too. "
          />
        </div>

        <div className="process-circle4"></div>
        <img className="process-img4" alt="process-img4" src={stockmarket} />
        <div className="process-email">
          <span className="process-email-heading">Let's Get Started</span>
          <EmailForm />
        </div>
      </section>

      <Testimonials />

      <ContactForm />
    </>
  )
};

export default Home;