import React,{ useState } from 'react';
import { useDispatch } from 'react-redux';
import { submitInfoRequest } from '../../store/home/actions';
import './style.scss';


const EmailForm = (props) => {
  const { width } = props;
  const [contactData, setcontactData] = useState({
    email: "",
    name:"",
    message:""
  });
  const dispatch = useDispatch();
  const handleChange = (event) => {
    setcontactData({ ...contactData, [event.target.name]: event.target.value });
    console.log("EVENT VALUE:", event.target.value);
  }

  const submitHandler = (event) => {
    event.preventDefault();
    console.log("SUBMIT CLICKED!!!:", contactData)
    dispatch(submitInfoRequest(contactData));
  }
  return (
    <>
      <div className="input-wrapper">
        <form onSubmit={submitHandler}>
          <div className="input-container">
            <input
              type="text"
              name="email"
              className="input-field"
              placeholder="Enter your email..."
              value={contactData.email}
              onChange={handleChange}
              style={{width: width}}
            />
            <button type="submit" className="submit-button"></button>
          </div>
        </form>
      </div>
    </>
  )
}

export default EmailForm;