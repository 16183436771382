import './style.scss';
import { Link } from 'react-router-dom';
const Navbar = () => {
  return (
    <>
      <nav className='navbar'>
        <input type="checkbox" id="check" className='checkbox' hidden/>
        <div className='navbar-hamburger-menu'>
          <label htmlFor="check" className='menu'>
            <div className='menu-line menu-line-1'></div>
            <div className='menu-line menu-line-2'></div>
            <div className='menu-line menu-line-3'></div>
          </label>
        </div>
        <div className='navbar-navigation'>
          <div className='navbar-navigation-right'>
            <ul className='nav-list'>
              <li className='nav-list-item'>
                <Link className='nav-list-link' to={'/'}>Home</Link>
              </li>
              <li className='nav-list-item'>
                <Link className='nav-list-link' to={'/about'}>About us</Link>
              </li>
              <li className='nav-list-item'>
                <Link className='nav-list-link' to={'/services'}>Services</Link>
              </li>
              <li className='nav-list-item'>
                <Link className='nav-list-link' to={'/products'}>Products</Link>
              </li>
              <li className='nav-list-item'>
                <Link className='nav-list-link' to={'/contact'}>Contact</Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  )
};

export default Navbar;