import React from 'react';
import './style.scss';

const ProcessCard = (props) => {
  const { text1, text2, paragraph } = props;
  return (
    <>
      <section className="process-card">
        <div className="process-card-icon">
          <props.icon />
        </div>
        <div className="process-card-headings">
          <h3 className="process-card-headings-text1">{text1}</h3>
          <span className="process-card-headings-text2">{text2}</span>
        </div>
        <p className="process-card-paragraph">{paragraph}</p>
      </section>
    </>
  )
}

export default ProcessCard;