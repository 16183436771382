import React from 'react';
import './style.scss';

const ArrowButtonInverse = (props) => {
  return (
    <>
      <div className="products-btn">
        <a href={props.href} className="btn-inv">
          <div className="btn-inv-bg"></div>
          <div className="arrow-inv">
            <div className="arrow-inv-line arrow-inv-line-1"></div>
            <div className="arrow-inv-line arrow-inv-line-2"></div>
            <div className="arrow-inv-line arrow-inv-line-3"></div>
          </div>
          <span className="btn-inv-text">{props.buttonText}</span>
        </a>
      </div>
    </>
  )
}

export default ArrowButtonInverse;