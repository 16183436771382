import React from 'react';

const ComputerScreen = () => {
  return (
    <>
      <svg width="700pt" height="700pt" version="1.1" viewBox="0 0 700 700" xmlns="http://www.w3.org/2000/svg" className="icons-panel-svg">
        <g>
          <path d="m53.031 551.52h224.85c-5.3047 24.395-26.516 42.426-51.969 42.426-6.3633 0-10.605 4.2422-10.605 10.605v47.727c0 6.3633 4.2422 10.605 10.605 10.605h248.18c6.3633 0 10.605-4.2422 10.605-10.605l-0.003907-47.727c0-6.3633-4.2422-10.605-10.605-10.605-25.453 0-46.668-18.031-51.969-42.426h224.85c23.332 0 42.426-19.09 42.426-42.426v-429.54c0-23.332-19.09-42.426-42.426-42.426h-593.94c-23.332 0-42.426 19.09-42.426 42.426v429.54c0 23.336 19.09 42.426 42.426 42.426zm410.45 62.574v27.574l-226.97 0.003907v-27.574c32.879-4.2422 58.332-30.758 62.574-62.574h100.76c5.3047 31.812 30.758 58.328 63.637 62.57zm183.48-83.785h-593.94c-11.668 0-21.211-9.5469-21.211-21.211v-71.062h636.36v71.059c0 11.668-9.5469 21.215-21.215 21.215zm-593.94-471.97h593.94c11.668 0 21.211 9.5469 21.211 21.211v336.21l-636.36 0.003906v-336.21c0-11.668 9.5469-21.215 21.215-21.215z" />
          <path d="m376.52 473.03h-53.031c-6.3633 0-10.605 4.2422-10.605 10.605s4.2422 10.605 10.605 10.605h53.031c6.3633 0 10.605-4.2422 10.605-10.605s-4.2422-10.605-10.605-10.605z" />
          <path d="m224.85 473.03h-21.211c-6.3633 0-10.605 4.2422-10.605 10.605s4.2422 10.605 10.605 10.605h21.211c6.3633 0 10.605-4.2422 10.605-10.605s-4.2422-10.605-10.605-10.605z" />
          <path d="m129.39 473.03h-21.211c-6.3633 0-10.605 4.2422-10.605 10.605s4.2422 10.605 10.605 10.605h21.211c6.3633 0 10.605-4.2422 10.605-10.605s-4.2422-10.605-10.605-10.605z" />
          <path d="m90.152 288.48h180.3c6.3633 0 10.605-4.2422 10.605-10.605v-162.27c0-6.3633-4.2422-10.605-10.605-10.605h-180.3c-6.3633 0-10.605 4.2422-10.605 10.605v162.27c0 6.3633 4.2422 10.605 10.605 10.605zm10.605-162.27h159.09v141.06h-159.09z" />
          <path d="m212.12 154.85h-39.242c-6.3633 0-10.605 4.2422-10.605 10.605s4.2422 10.605 10.605 10.605h13.789l-45.605 45.605c-4.2422 4.2422-4.2422 10.605 0 14.848 4.2422 4.2422 10.605 4.2422 14.848 0l45.605-45.602v13.789c0 6.3633 4.2422 10.605 10.605 10.605 6.3633 0 10.605-4.2422 10.605-10.605v-39.242c0-6.3672-5.3008-10.609-10.605-10.609z" />
          <path d="m354.24 126.21h255.61c6.3633 0 10.605-4.2422 10.605-10.605s-4.2422-10.605-10.605-10.605h-255.61c-6.3633 0-10.605 4.2422-10.605 10.605 0 5.3047 4.2422 10.605 10.605 10.605z" />
          <path d="m354.24 207.88h255.61c6.3633 0 10.605-4.2422 10.605-10.605 0-6.3633-4.2422-10.605-10.605-10.605h-255.61c-6.3633 0-10.605 4.2422-10.605 10.605 0 5.3008 4.2422 10.605 10.605 10.605z" />
          <path d="m354.24 288.48h255.61c6.3633 0 10.605-4.2422 10.605-10.605 0-6.3633-4.2422-10.605-10.605-10.605h-255.61c-6.3633 0-10.605 4.2422-10.605 10.605 0 6.3633 4.2422 10.605 10.605 10.605z" />
          <path d="m90.152 370.15h520.76c6.3633 0 10.605-4.2422 10.605-10.605s-4.2422-10.605-10.605-10.605l-520.76-0.003906c-6.3633 0-10.605 4.2422-10.605 10.605 0 5.3047 4.2422 10.609 10.605 10.609z" />
        </g>                                                                                                         
      </svg>
    </>
  );
}
export default ComputerScreen;