import React from 'react';

const LightBulb = (props) => {
  return (
    <>
      <svg width="700pt" height="700pt" version="1.1" viewBox="0 0 700 700" xmlns="http://www.w3.org/2000/svg" className="process-svg">
        <g>
          <path d="m358.4 134.4c-50.398-2.1016-98.699 15.398-135.1 50.398-36.398 35-56.699 81.898-56.699 132.3 0 41.301 13.301 80.5 39.199 113.4 22.398 28.699 37.801 56 47.602 84.699l0.69922 2.1016v73.5c0 9.1016 7 17.5 16.102 18.898 3.5 40.605 37.801 72.805 79.801 72.805s76.301-32.199 79.801-73.5c9.1016-1.3984 16.102-9.1016 16.102-18.898l-0.003906-72.801 0.69922-2.1016c9.8008-28.699 25.199-56 47.602-84 27.301-34.301 41.301-77.699 39.199-121.1-4.8984-94.496-81.199-171.5-175-175.7zm49 390.6v46.199h-114.8v-46.199zm-57.398 119c-21 0-37.801-15.398-41.301-35h82.602c-3.5 20.301-20.301 35-41.301 35zm113.4-237.3c-21 26.602-36.398 52.5-47.602 79.801h-131.6c-11.199-26.602-26.602-53.199-47.602-79.801-20.301-25.898-30.801-56.699-30.801-89.602 0-39.898 16.102-77 44.801-105 28.699-27.301 66.5-42 107.1-39.898 74.199 3.5 134.4 64.398 137.9 137.9 0.70312 35.703-10.496 69.301-32.199 96.602z"/>
          <path d="m350 91c10.5 0 18.898-8.3984 18.898-18.898v-35.703c0-10.5-8.3984-18.898-18.898-18.898s-18.898 8.3984-18.898 18.898v35.699c0 10.504 8.3984 18.902 18.898 18.902z"/>
          <path d="m210.7 114.8c3.5 6.3008 9.8008 9.8008 16.801 9.8008 3.5 0 6.3008-0.69922 9.8008-2.8008 9.1016-5.6016 12.602-16.801 7-25.898l-17.5-30.801c-5.6016-9.1016-16.801-12.602-25.898-7-9.1016 5.6016-12.602 16.801-7 25.898z"/>
          <path d="m147 177.8-30.801-17.5c-9.1016-5.6016-21-2.1016-25.898 7-5.6016 9.1016-2.1016 21 7 25.898l30.801 17.5c2.8008 1.3984 6.3008 2.8008 9.8008 2.8008 6.3008 0 13.301-3.5 16.801-9.8008 4.8984-8.3984 1.3984-20.301-7.7031-25.898z"/>
          <path d="m123.9 317.1c0-10.5-8.3984-18.898-18.898-18.898l-35.699-0.003906c-10.5 0-18.898 8.3984-18.898 18.898s8.3984 18.898 18.898 18.898l35.699 0.003906c10.5 0.69922 18.898-8.3984 18.898-18.898z"/>
          <path d="m128.1 423.5-30.801 17.5c-9.1016 5.6016-12.602 16.801-7 25.898 3.5 6.3008 9.8008 9.8008 16.801 9.8008 3.5 0 6.3008-0.69922 9.8008-2.8008l30.801-17.5c9.1016-5.6016 12.602-16.801 7-25.898-5.6016-9.1016-17.504-12.602-26.602-7z"/>
          <path d="m602.7 441-30.801-17.5c-9.1016-5.6016-21-2.1016-25.898 7-5.6016 9.1016-2.1016 21 7 25.898l30.801 17.5c2.8008 1.3984 6.3008 2.8008 9.8008 2.8008 6.3008 0 13.301-3.5 16.801-9.8008 4.1992-9.0977 1.3984-20.297-7.7031-25.898z"/>
          <path d="m630.7 298.2h-35.699c-10.5 0-18.898 8.3984-18.898 18.898s8.3984 18.898 18.898 18.898h35.699c10.5 0 18.898-8.3984 18.898-18.898 0.003906-10.496-8.3984-18.898-18.898-18.898z"/>
          <path d="m562.1 213.5c3.5 0 6.3008-0.69922 9.8008-2.8008l30.801-17.5c9.1016-5.6016 12.602-16.801 7-25.898-5.6016-9.1016-16.801-12.602-25.898-7l-30.805 17.5c-9.1016 5.6016-12.602 16.801-7 25.898 3.5 7 9.8008 9.8008 16.102 9.8008z"/>
          <path d="m462.7 121.8c2.8008 1.3984 6.3008 2.8008 9.8008 2.8008 6.3008 0 13.301-3.5 16.801-9.8008l17.5-30.801c5.6016-9.1016 2.1016-21-7-25.898-9.1016-5.6016-21-2.1016-25.898 7l-17.5 30.801c-5.6016 8.3984-2.8008 20.297 6.2969 25.898z"/>
        </g>
      </svg>
    </>
  );
}
export default LightBulb;