import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { submitInfoRequest } from '../../store/home/actions';
import PhoneImage from '../../images/phone.png'
import './style.scss';

const ContactForm = () => {
  const dispatch = useDispatch();
  const [contactData, setcontactData] = useState({
    name: "",
    email: "",
    message: ""
  });
  const [buttonText, setButtonText] = useState('Send A Message');
  const [isDisabled,setIsDisabled] = useState();
  const handleChange = (event) => {
    setcontactData({ ...contactData, [event.target.name]: event.target.value });
  }

  const submitHandler = (event) => {
    event.preventDefault();
    setButtonText('Request Submitted');
    setIsDisabled(true);
    dispatch(submitInfoRequest(contactData));
  }

  return (
    <section className="contact-form">
      <div className="contact-form-inputs">
        
        <form className="contact-form-inputs-form" onSubmit={submitHandler}>
          <h3 className="contact-form-inputs-form-heading">Contact.</h3>
          <input
            type="text"
            name="name"
            className="contact-form-inputs-form-name underline"
            placeholder="Your name..."
            value={contactData.name}
            onChange={handleChange}
            disabled={isDisabled}
            required
          />
          <input
            type="text"
            name="email"
            className="contact-form-inputs-form-email underline"
            placeholder="Your email..."
            value={contactData.email}
            onChange={handleChange}
            disabled={isDisabled}
            required
          />
          <br></br>
          <textarea
            type="text"
            name="message"
            className="contact-form-inputs-form-message underline"
            placeholder="Your message..."
            value={contactData.message}
            onChange={handleChange}
            disabled={isDisabled}
            required
          />
          <button 
            type="submit"
            className="contact-form-inputs-form-button"
          >
            {buttonText}
          </button>
          <p className="contact-form-inputs-form-heading2">
            Contact Info
          </p>
          <p className="contact-form-inputs-form-phone">
            (941)961-4952
          </p><br></br>
          <p className="contact-form-inputs-form-emailinfo">
            hello@gottagetbread.com
          </p>
          {/* <p className="contact-form-inputs-form-socialinfo">
            Social
          </p> */}

        </form>
      </div>
      <div className="contact-form-image">
        <img src={PhoneImage} alt="phone" />
      </div>

    </section>
  );
}

export default ContactForm;