import './style.css';
import './style.scss';
import logoText from './images/getBreadWhiteText.png';
import Navbar from '../navbar';


const Header = () => {
  return (
    <>
      <section className="banner">
        <Navbar/>
        <img className="banner-logo" alt="banner logo" src={logoText}/>
      </section>
    </>
  )
}

export default Header;
