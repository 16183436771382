import React from 'react';

const Growth = () => {
  return (
    <>
      <svg width="700pt" height="700pt" version="1.1" viewBox="0 0 700 700" xmlns="http://www.w3.org/2000/svg" className="process-svg">
        <path d="m545.05 387.61v-79.238c0-6.8516-5.5664-12.41-12.434-12.41h-170.2v-37.066c18.656-0.875 57.82-6 84.078-32.262 35.051-35.043 32.488-92.492 32.359-94.914-0.34375-6.3555-5.4102-11.445-11.762-11.762-0.16016-0.027343-1.6328-0.097656-4.1016-0.097656-13.691 0-60.73 2.3516-90.832 32.445-7.7344 7.7266-13.629 16.539-18.129 25.633-6-17.227-15.344-34.902-29.902-49.449-37.52-37.527-96.496-40.453-113.67-40.453-3.0508 0-4.8281 0.097656-5.0273 0.097656-6.3633 0.34375-11.445 5.4102-11.781 11.773-0.14844 3.0391-3.375 74.992 40.348 118.71 32.02 32.004 79.605 38.828 103.58 40.152v37.191l-170.2 0.003906c-6.8516 0-12.418 5.5664-12.418 12.41v79.238c0 6.8516 5.5664 12.41 12.418 12.41h18.633l17.43 60.824c1.8984 6.6016 8.7656 10.402 15.379 8.5195 6.5781-1.8906 10.395-8.7656 8.5195-15.363l-15.484-53.977h281.04l-53.621 187.09-173.78-0.003906-20.285-70.762c-1.8984-6.6016-8.7773-10.414-15.371-8.5195-6.5938 1.8906-10.395 8.7656-8.5195 15.352l22.883 79.785c1.5117 5.3203 6.3906 8.9961 11.93 8.9961h192.52c5.5508 0 10.414-3.6758 11.949-8.9961l58.164-202.95h13.867c6.8594-0.003906 12.422-5.5625 12.422-12.418zm-155.31-217.73c18.844-18.836 47.676-23.652 64.008-24.816-1.1406 16.191-5.9219 45.102-24.809 64-18.844 18.836-47.676 23.652-64.016 24.816 1.1484-16.203 5.9258-45.121 24.816-64zm-171.09-56.781c20.391 1.0234 61.453 6.5117 87.914 32.965 26.398 26.391 31.887 67.746 32.93 87.949-20.391-1.0234-61.453-6.5234-87.914-32.965-26.387-26.387-31.871-67.742-32.93-87.949zm-23.281 262.09h-15.574v-54.391h340.39v54.391z"/>
      </svg>
    </>
  );
}
export default Growth;