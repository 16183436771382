import './style.scss';

const Services = () => {

  return(
    <div className="home-wrapper">
      <h1>Services Page</h1>
    </div>
  )
};

export default Services;