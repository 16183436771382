import React from 'react';
import './style.scss';

const Testimonials = (props) => {
  return (
    <section className="testimonials">
      <section className="testimonials-content">
        <span className="testimonials-heading">
          Testimonials
        </span>
        <p className="testimonials-text">
          I have had the opportunity and pleasure of working with Seth
          Stahimann...Seth consistentlu demonstrated exceptional skills in video production, editing, and content creation.
          He is true professional who takes the time to understand not only the mission and vision...but also the specific
          needs of agents and industru professionals in creating engaging, informative content, utilizing the specific client needs as the catalyst.
          Seth has a keen eye for detail and his videos are always stunning...He is a talented,
          dedicated. and resourceful professiona who consistentlu delivers outstanding work.
          <br></br>
          Brian Craft
        </p>
      </section>
    </section>
  )
}

export default Testimonials;