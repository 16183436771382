import axios from 'axios';
import { takeEvery, call, put } from 'redux-saga/effects';
import {
  INIT_HOME_PAGE_VALUE,
  SUBMIT_INFO_REQUEST
} from "./actionTypes";
import { 
  initHomePageValueSuccess,
  initHomePageValueFailure,
  submitInfoRequestSuccess,
  submitInfoRequestFailed
 } from './actions';
const baseUrl = `https://www.gottagetbread.com/api`;

export default function* HomeValueSaga() {
  yield takeEvery(INIT_HOME_PAGE_VALUE, HomePageSaga)
  yield takeEvery(SUBMIT_INFO_REQUEST, HomePageInfoRequestSaga)
}

function* HomePageSaga(action) {
  const headerParams = {
    'content-type': 'application/json'
  }
  try {
    const response = yield call(axios.get, `${baseUrl}/homepage`, { headers: headerParams })
    const payload = {
      success: true,
      response: response.data
    }
    if (response.status === 200) {
      yield put(initHomePageValueSuccess(payload))
    }
  } catch (err) {
    yield put(initHomePageValueFailure(true))
  }
}

function* HomePageInfoRequestSaga(action) {
  const headerParams = {
    'content-type': 'application/json'
  }
  try {
    const response = yield call(axios.post, `${baseUrl}/homepage`,action.formData, { headers: headerParams })
    const payload = {
      success: true,
      response: response.data
    }
    if (response.status === 200) {
      yield put(submitInfoRequestSuccess(payload))
    }
  } catch (err) {
    yield put(submitInfoRequestFailed(true))
  }
}