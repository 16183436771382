import {
  INIT_HOME_PAGE_VALUE,
  SAVE_HOME_PAGE_VALUE_SUCCESS,
  SAVE_HOME_PAGE_VALUE_FAILED,
  SUBMIT_INFO_REQUEST,
  SUBMIT_INFO_REQUEST_SUCCESS,
  SUBMIT_INFO_REQUEST_FAILED
} from "./actionTypes";

export function initHomePageValue(noteValue){
  return {
    type: INIT_HOME_PAGE_VALUE,
    noteValue
  }
}

export function initHomePageValueSuccess(payload){
  return {
    type: SAVE_HOME_PAGE_VALUE_SUCCESS,
    payload
  }
}

export function initHomePageValueFailure(failed){
  return {
    type: SAVE_HOME_PAGE_VALUE_FAILED,
    failed
  }
}

export function submitInfoRequest(formData){
  return {
    type: SUBMIT_INFO_REQUEST,
    formData
  }
}

export function submitInfoRequestSuccess(payload){
  return {
    type: SUBMIT_INFO_REQUEST_SUCCESS,
    payload
  }
}

export function submitInfoRequestFailed(failed){
  return {
    type: SUBMIT_INFO_REQUEST_FAILED,
    failed
  }
}