import React from 'react';
import './style.scss';
import ArrowButton from '../ArrowButton';
const IconCard = (props) => {
  return (
    <>
      <section className="icon-card">
        <div className="flex-item-1">
          <props.iconImage />
        </div>
        <div className="flex-item-2">
          <h3 className="flex-heading">{props.headingText}</h3>
          <p>{props.paragraphText}</p>
          <ArrowButton 
            buttonText={props.buttonText}
            href={props.href}
          />
        </div>
      </section>
    </>
  )
}

export default IconCard;