import React from "react";
import './style.scss';
import GetBreadLogo from '../../images/getbreadlogo.png';
const Footer = () => {
  return (
    <section className="footer">
      <div className="footer-logo">
        <img alt="GetBreadLogo" className="footer-logo" src={GetBreadLogo} />
      </div>
      <div className="footer-contact">
        <span className="footer-contact-heading">Let's Chat</span>
        <p className="footer-contact-phone">(941)961-4952</p>
        <p className="footer-contact-email"><a href="mailto:hello@gottagetbread.com">hello@gottagetbread.com</a></p>
      </div>
    </section>
  )
}

export default Footer;